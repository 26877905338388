import React, { useContext, useEffect, useRef, useState } from "react";
import "./dashboard.css";
import Nav from "./Nav";
import { userContext } from "../../App";
import { IoMdArrowDropdown } from "react-icons/io";
import Progress from "../../components/Progress/Progress";
import axios from "../../utils/axios";
import Post from "./Post";
import Spinner from "../Spinner/Spinner";

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const category = useRef(null);
  const { user, token } = useContext(userContext);
  const stats = useRef(null);
  const userCategory = user?.category?._id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get("/api/category");
        const jsonResult = await result.data;
        category.current = jsonResult.data;
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .get(
        user.role === "superAdmin"
          ? `/api/complaint/stats${
              currentCategory ? `?category=${currentCategory._id}` : ""
            }`
          : `/api/complaint/stats?category=${userCategory}`,
        config
      )
      // user.role === "superAdmin" && currentCategory != null ?
      // axios.get(`/api/complaint/stats?category=${currentCategory._id}`, config) :
      // axios.get(`/api/complaint/stats?category=${userCategory}`, config)
      .then(function (response) {
        stats.current = response.data.data;
        setLoading(false);
      })
      .catch(function (err) {
        console.log(err.message);
        setLoading(false);
      });
  }, [category, token, user.role, currentCategory, userCategory]);
  useEffect(() => {
    if (showCategory) {
      document.querySelector("body").style.overflow = "hidden";
    }
    return () => {
      document.querySelector("body").style.overflow = "auto";
    };
  }, [showCategory]);
  function handle(category) {
    if (category === "all") {
      setCurrentCategory("");
    } else {
      setCurrentCategory(category);
    }
    setShowCategory(false);
  }
  function show(e) {
    setShowCategory(!showCategory);
  }

  return (
    <div className="dashboard">
      <div className="home-container">
        <h1>Dashboard</h1>
        <div className="statistics">
          <div className="top">
            <h2>Tickets Statistics</h2>
            {
              user.role === "superAdmin" && (
                // (loading ? <Spinner /> :
                <div className="dropdown">
                  <div className="dropbtn" onClick={show}>
                    <p>
                      {currentCategory === null
                        ? "All"
                        : currentCategory?.category}
                    </p>
                    <IoMdArrowDropdown className="down-arrow" />
                  </div>
                  {showCategory && (
                    <div className="dropdown-content">
                      <p onClick={() => handle(null)}>all</p>
                      {category.current !== null &&
                        category.current.map((category, index) => {
                          return (
                            <p key={index + 1} onClick={() => handle(category)}>
                              {category.category}
                            </p>
                          );
                        })}
                    </div>
                  )}
                </div>
              )
              // )
            }
          </div>
          <div className={loading ? "stat loading" : "stat"}>
            {stats.current === null || loading ? (
              <Spinner />
            ) : (
              <>
                <div className="total">
                  <Progress percentage={100} />
                  <div>
                    <p>{stats.current.allTickets}</p>
                    <p>Total</p>
                  </div>
                </div>
                <div className="active">
                  <Progress
                    percentage={Math.round(
                      (stats.current.openedTickets / stats.current.allTickets) *
                        100
                    )}
                  />
                  <div>
                    <p>{stats.current.openedTickets}</p>
                    <p>Open</p>
                  </div>
                </div>
                {user.role === "superAdmin" && currentCategory !== null && (
                  <div className="close">
                    <Progress
                      percentage={Math.round(
                        (stats.current.queriedCategoryTickets /
                          stats.current.allTickets) *
                          100
                      )}
                    />
                    <div>
                      <p>{stats.current.queriedCategoryTickets}</p>
                      <p>Queried Category Tickets</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <Post />
      </div>
      <Nav />
    </div>
  );
}

export default Dashboard;

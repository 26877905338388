import React from "react";
import { SlOptionsVertical } from "react-icons/sl";

function TableRow({
  category,
  ind,
  setInd,
  index,
  openState,
  setOpenState,
  setData,
  setShowAddCategory,
  setShowDeleteCategory,
  setShowEditCategory,
}) {
  const handleClick = (e) => {
    e.stopPropagation();
    if (ind === index) {
      setInd(0);
      setOpenState(false);
    } else {
      setInd(index);
      setOpenState(true);
    }
  };
  const showEditCategoryForm = () => {
    setShowAddCategory(false);
    setShowDeleteCategory(false);
    setShowEditCategory(true);
    setInd(0);
    setOpenState(false);
  };
  const showDeleteCategoryForm = () => {
    setShowAddCategory(false);
    setShowDeleteCategory(true);
    setShowEditCategory(false);
    setInd(0);
    setOpenState(false);
  };
  return (
    <tr>
      <td className="category-name">{category.category}</td>
      <td className="category-email">{category.categoryEmail}</td>
      <td className={`dropdown${openState && ind === index ? " active" : ""}`}>
        <SlOptionsVertical onClick={(e) => handleClick(e)} />
        <div
          id="dropdown"
          onClick={() => setData(category)}
          className="dropdown-content"
        >
          <p onClick={showEditCategoryForm}>Edit</p>
          <p onClick={showDeleteCategoryForm}>Delete</p>
        </div>
      </td>
    </tr>
  );
}

export default TableRow;

import React, { useRef, useState, useEffect, useContext } from "react";
import axios from "../../utils/axios";
import { userContext } from "../../App";
import Spinner from "../Spinner/Spinner";

function Post() {
  const { user, config } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const tickets = useRef(null);
  const openTickets = useRef(null);
  const num = useRef(null);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        user.role !== "superAdmin"
          ? "/api/complaint/fetch?recent=true"
          : "/api/complaint/fetch-all?status=open",
        config
      )
      .then(function (response) {
        const numArray = [4, 3, 2, 1, 0];
        tickets.current = response.data.data;
        openTickets.current = tickets.current.slice(
          tickets.current.length >= 5 ? -5 : -1 * tickets.current.length
        );
        num.current = numArray.slice(
          tickets.current.length >= 5 ? -5 : -1 * tickets.current.length
        );

        setLoading(false);
      })
      .catch(function (err) {
        console.log(err.response.data.message || err.message);
        setLoading(false);
      });
  }, [config, user]);


  return (
    <div className="post">
      <h2>Recent Tickets</h2>
      <div className={loading ? "loading" : ""}>
        {openTickets?.current === null ? (
          <Spinner />
        ) : openTickets?.current?.length !== 0 ? (
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Class Type</th>
                  <th scope="col">Ticket Category</th>
                  <th scope="col">Date of Opening</th>
                </tr>
              </thead>
              <tbody>
                {num.current.map((num, index) => {
                  return (
                    <tr key={index + 1}>
                      <td className="table-name">
                        {openTickets?.current[num]?.name}
                      </td>
                      <td className="table-email">
                        {openTickets?.current[num]?.email}
                      </td>
                      <td className="class-type">
                        {openTickets?.current[num]?.classType}
                      </td>
                      <td className="table-category">
                        {openTickets?.current[num]?.category?.category}
                      </td>
                      <td className="table-date">
                        {openTickets?.current[num]?.createdAt?.substring(0, 10)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No recent ticket</p>
        )}
      </div>
    </div>
  );
}

export default Post;

import React, { useEffect, useContext, useRef, useState } from "react";
import Nav from "../Dashboard/Nav";
import "./profile.css";
import axios from "../../utils/axios";
import { userContext } from "../../App";
import Spinner from "../Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const success = () => {
  toast.success("Password updated successfully", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const failure = () =>
  toast.error("🦄 Error, Invalid password!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });

function Profile() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const { token } = useContext(userContext);
  const userData = useRef(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      try {
        axios
          .get(`/api/users/user/profile`, config)
          .then(function (response) {
            userData.current = response.data.data;
            setLoading(false);
          });
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    axios
      .put(
        `/api/users/password/update`,
        data,
        config
      )
      .then(function (res) {
        success();
        setData({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      })
      .catch(function (err) {
        console.log(err.response.data.message || err.message);
        failure();
      });
  };

  return (
    <div className="profile">
      <div className={loading ? "userData loading" : "userData"}>
        {loading ? (
          <div className="spinner-div">
            <Spinner />
          </div>
        ) : (
          <div className="profile-container">
            <h1>Profile Page</h1>
            {userData.current !== null && (
              <div className="profile-form">
                <form>
                  <div className="profile-content-firstname">
                    <p>First name</p>
                    <input
                      type="text"
                      value={userData.current.firstName}
                      readOnly
                    />
                  </div>
                  <div className="profile-content-middlename">
                    <p>Middle name</p>
                    <input
                      type="text"
                      value={userData.current.middleName}
                      readOnly
                    />
                  </div>
                  <div className="profile-content-lastname">
                    <p>Last name</p>
                    <input
                      type="text"
                      value={userData.current.lastName}
                      readOnly
                    />
                  </div>
                  <div className="profile-content-email">
                    <p>Email</p>
                    <input
                      type="email"
                      value={userData.current.email}
                      readOnly
                    />
                  </div>
                  <div className="profile-content-role">
                    <p>Role</p>
                    <input type="text" value={userData.current.role} readOnly />
                  </div>
                  {userData.current.role !== "superAdmin" && (
                    <div className="user-category">
                      <p>Category</p>
                      <input
                        type="text"
                        value={userData.current.category}
                        readOnly
                      />
                    </div>
                  )}
                </form>
              </div>
            )}

            <form onSubmit={handleSubmit} className="update-password">
              <div className="old-password">
                <p>Old Password</p>
                <input
                  type="text"
                  value={data.oldPassword}
                  onChange={(e) =>
                    setData({ ...data, oldPassword: e.target.value })
                  }
                  placeholder=""
                />
              </div>
              <div className="new-password">
                <p>New Password</p>
                <input
                  type="text"
                  value={data.newPassword}
                  onChange={(e) =>
                    setData({ ...data, newPassword: e.target.value })
                  }
                  placeholder=""
                />
              </div>
              <div className="confirm-password">
                <p>Confirm Password</p>
                <input
                  type="text"
                  value={data.confirmPassword}
                  onChange={(e) =>
                    setData({ ...data, confirmPassword: e.target.value })
                  }
                  placeholder=""
                />
              </div>
              <div className="update-btn">
                <button type="submit" className="update-button">
                  Update password
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <ToastContainer />
      <Nav />
    </div>
  );
}

export default Profile;

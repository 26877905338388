import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/LogIn/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Tickets from "./components/Tickets/Tickets";
import Profile from "./components/Profile/Profile";
import User from "./components/User/User";
import Category from "./components/Category/Category";
import ReplyTicket from "./components/Tickets/SupportTickets/ReplyTicket";

export const userContext = React.createContext(null);
const App = () => {
  let currentUser, token;
  if (localStorage.getItem("user")) {
    currentUser = JSON.parse(localStorage.getItem("user"));
    token = localStorage.getItem("token");
  } else if (sessionStorage.getItem("user")) {
    currentUser = JSON.parse(sessionStorage.getItem("user"));
    token = sessionStorage.getItem("token");
  }
  const [user, setUser] = useState(currentUser || null);
  const [currentToken, setCurrentToken] = useState(token || null);
  const config = {
    headers: {
      Authorization: `Token ${currentToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return (
    <div className="app">
      <Router>
        <userContext.Provider
          value={{
            user: user,
            setUser: setUser,
            token: currentToken,
            setCurrentToken: setCurrentToken,
            config: config,
          }}
        >
          <Routes>
            <Route path={"/"} element={<Login />} />
            <Route path={"/login"} exact element={<Login />} />
            <Route path={"/dashboard"} element={<Dashboard />} />
            <Route path={"/tickets"} element={<Tickets />} />
            <Route path={"/tickets/:id"} element={<Tickets />} />
            <Route path={"/users"} element={<User />} />
            <Route path={"/category"} element={<Category />} />
            <Route path={"/profile"} element={<Profile />} />
            <Route path={"/reply"} element={<ReplyTicket />} />
          </Routes>
        </userContext.Provider>
      </Router>
    </div>
  );
};

export default App;

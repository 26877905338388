import React, { useState, useRef, useEffect, useContext } from "react";
import axios from "../../utils/axios";
import jwt_decode from "jwt-decode";
import "../form.css";
// import * as FaIcons from "react-icons/fa";
import * as GoIcons from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { userContext } from "../../App";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PasswordInput from "../PasswordInput";
import Logo from "../Logo";
import bgImg from "../../assets/Outsource-IT-Help-Desk-Services.png";
import BeatLoader from "react-spinners/BeatLoader";

function Login() {
  let loginUser;

  const matches = useMediaQuery("(max-width: 900px)");

  const { setUser, user, setCurrentToken } = useContext(userContext);
  const navigate = useNavigate();
  const remember = useRef();
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });
  // const [showPassword, setShowPassword] = useState(false);
  let localSAuth = JSON.parse(localStorage.getItem("auth"));
  let sessionSAuth = JSON.parse(sessionStorage.getItem("auth"));
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const emailValue = userDetails.email.trim();
  const passwordValue = userDetails.password.trim();

  const [loading, setLoading] = useState(false);

  function isEmail(email) {
    return /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }
  function checkInputs() {
    // trim to remove the whitespaces
    if (emailValue === "") {
      setEmailError("Email cannot be empty");
    } else if (!isEmail(emailValue)) {
      setEmailError("Looks like this is not an email");
    }
    if (passwordValue === "") {
      setPasswordError("Password cannot be empty");
    } else if (passwordValue.length < 6) {
      setPasswordError("password must have minimum of 6 characters");
    }
  }

  const resetError = async (e) => {
    if (passwordError !== "") setPasswordError("");
    if (emailError !== "") setEmailError("");
    if (errorMessage) setErrorMessage("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // <<<<<<< latest-changes
    //     await checkInputs ();
    //     if(emailError !== ""  ||  passwordError !== ""){
    //       return 0;
    //     }
    //     axios.post("https://helpdesk.ulinin.com/api/auth", userDetails)
    //     .then(function (response) {
    //       loginUser = jwt_decode(response.data.token);
    //       if(remember.current.checked){
    //         setUser(loginUser)
    //         setCurrentToken(response.data.token)
    //         localStorage.setItem("auth", 1)
    //         localStorage.setItem("user", JSON.stringify(loginUser))
    //         localStorage.setItem("token", JSON.stringify(response.data.token))
    //         localStorage.setItem("remember", 1)
    //       } else{
    //         setUser(loginUser)
    //         sessionStorage.setItem("auth", 1)
    //         sessionStorage.setItem("user", JSON.stringify(loginUser))
    //         sessionStorage.setItem("token", JSON.stringify(response.data.token))
    //         localStorage.setItem("remember", 0)
    //       }
    //       navigate("/dashboard")
    // =======
    setLoading(true);
    new Promise(function (resolve, reject) {
      resolve(checkInputs());
    })
      .then(function () {
        if (emailValue === "" || passwordValue === "") {
          setLoading(false);
          throw new Error(
            `${
              emailValue !== "" && passwordValue === ""
                ? "password field is blank"
                : emailValue === "" && passwordValue !== ""
                ? "email field is blank"
                : "email and password fields are blank"
            }`
          );
        }
      })
      .then(function () {
        axios
          .post("/api/auth", userDetails)
          .then(function (response) {
            loginUser = jwt_decode(response.data.token);
            if (remember.current.checked) {
              setUser(loginUser);
              setCurrentToken(response.data.token);
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("auth", true);
              localStorage.setItem("user", JSON.stringify(loginUser));
              localStorage.setItem("remember", 1);
              setLoading(false);
            } else {
              setUser(loginUser);
              setCurrentToken(response.data.token);
              sessionStorage.setItem("token", response.data.token);
              sessionStorage.setItem("auth", true);
              sessionStorage.setItem("user", JSON.stringify(loginUser));
              localStorage.setItem("remember", 0);
            }
            setLoading(false);
          })
          .then(function () {
            if (localStorage.getItem("user") || sessionStorage.getItem("user"))
              navigate("/dashboard");
            setLoading(false);
          })
          .catch(function (err) {
            setLoading(false);
            setErrorMessage(err.response.data.message || err.message);
          });
      })
      .catch(function (err) {
        // setErrorMessage(err.response.data.message || err.message)
        console.error(err);
        setLoading(false);
      });
  };

  // (()=>{
  // if(localStorage.getItem("auth") || sessionStorage.getItem("auth")){
  //     if(Date.now() < (user.exp * 1000)) navigate("/dashboard")
  //   }
  // })()
  useEffect(() => {
    if (localSAuth || sessionSAuth) {
      if (Date.now() < user.exp * 1000) navigate("/dashboard");
    }
  });

  useEffect(() => {
    if (localSAuth || sessionSAuth) {
      navigate("/dashboard");
    }
  }, [navigate, localSAuth, sessionSAuth]);
  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box"
      }}
    >
      <Grid
        container
        spacing={{ xs: 0, sm: 3, md: 5 }}
        sx={{
          width: "100% !important" ,
          height: { xs: "auto", md: "100%" },
          m: "0 !important",
          pt: 5,
          paddingBottom: 5,
          mt: 0,
          boxSizing: "border-box",
          px: { xs: 2, sm: 4, md: 6 },
          flexDirection: { xs: "column-reverse", md: "row" },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: matches ? "none" : "block",
            width: "100%",
            height: "100%",
            paddingTop: "6rem !important",
          }}
        >
          <Box
            sx={{
              margin: "0 auto",
              width: { xs: "80%", md: "100%" },
              height: 400,
              backgroundImage: `url(${bgImg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          ></Box>
        </Grid>

        <Grid
          sx={{
            width: "100%",
            height: "100%",
          }}
          item
          xs={12}
          md={6}
        >
          <Stack
            spacing={{ xs: 1, md: 1.5 }}
            alignItems="center"
            justifyContent="center"
          >
            <Logo />
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: 600,
                letterSpacing: "2px",
                fontSize: { xs: "1.7rem", sm: "2rem", md: "3rem" },
              }}
              width={{ xs: "100%", md: "90%" }}
              variant="h3"
            >
              University of Ibadan <br />
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: { xs: "1.2rem", md: "2rem" },
              }}
              width={{ xs: "100%", md: "90%" }}
              variant="body2"
            >
              Help Desk Support
            </Typography>
          </Stack>

          <Stack sx={{ margin: "0 auto", width: { xs: "100%", md: "80%" } }}>
            {/* <Typography
              sx={{ fontSize: "18px", my: 2, color: "rgba(0, 0, 0, 0.5)" }}
              variant="subtitle1"
            >
              Login
            </Typography> */}
            <form
              style={{ width: "100%", marginTop: "20px" }}
              onSubmit={handleSubmit}
            >
              <Stack width="100%" justifyContent="center" spacing={1}>
                <div>
                  <TextField
                    fullWidth
                    type="email"
                    name="email"
                    label="Email Address"
                    variant="outlined"
                    onClick={resetError}
                    onChange={(e) =>
                      setUserDetails({ ...userDetails, email: e.target.value })
                    }
                  />

                  {emailError !== "" && (
                    <div className="login-error">
                      <GoIcons.GoAlert color={"red"} />
                      <small>{emailError}</small>
                    </div>
                  )}
                </div>
                <div>
                  <PasswordInput
                    label="Password"
                    click={resetError}
                    name="password"
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        password: e.target.value,
                      })
                    }
                  />

                  {passwordError !== "" && (
                    <div className="login-error">
                      <GoIcons.GoAlert color={"red"} />
                      <small>{passwordError}</small>
                    </div>
                  )}
                </div>

                <Stack direction="row" alignItems="center" spacing={1}>
                  <input
                    type="checkbox"
                    name="rememberMe"
                    id="rememberMe"
                    ref={remember}
                  />
                  <label htmlFor="rememberMe">Remember me</label>
                </Stack>

                <div className="error">
                  {errorMessage !== "" && (
                    <div>
                      <GoIcons.GoAlert color={"red"} />
                      <small>{errorMessage}</small>
                    </div>
                  )}
                </div>
                <Button variant="contained" type="submit" sx={{height:"40px"}}>
                  {loading ? <BeatLoader color="white" /> : "Login"}
                </Button>
              </Stack>
            </form>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Login;

import React, { useState, useContext } from "react";
import axios from "../../../utils/axios";
import { userContext } from "../../../App";
import { toast, ToastContainer } from "react-toastify";
import * as GoIcons from "react-icons/go";
import Spinner from "../../Spinner/Spinner";

function SupportTeam({ close, category }) {
  const { config } = useContext(userContext);
  const [errorMessage, setErrorMessage] = useState("");
  const initialData = {
    email: "",
    category: "",
    categoryEmail: "",
  };
  const [data, setData] = useState(initialData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // <<<<<<< latest-changes
    //         axios.put("https://helpdesk.ulinin.com/api/users/support-user/change-category", data, config)
    // =======
    let chosenCategory, requestBody;
    await (async () => {
      if (category !== null) {
        chosenCategory = await category.find(
          (item) =>
            item.category === data.category && data.category !== "category"
        );
        requestBody = {
          email: data.email,
          category: data.category,
          categoryEmail: chosenCategory.categoryEmail,
        };
      }
    })();
    axios
      .put(
        "/api/users/support-user/change-category",
        requestBody,
        config
      )

      .then(function (response) {
        toast.success("User Category Updated Successfully!!!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setData(initialData);
        setTimeout(() => {
          close();
        }, 5000);
      })
      .catch(function (err) {
        setErrorMessage(err.response.data.message || err.message);
        toast.error("Request failled, Try Again!!!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const resetError = () => {
    if (errorMessage) setErrorMessage("");
  };
  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        value={data.email}
        onClick={resetError}
        onChange={(e) => setData({ ...data, email: e.target.value })}
        placeholder="Email"
      />
      {/* <select onChange={(e)=>setData({...data, category: e.target.value})} value={data.category} name="category" id="categories_of_people" required>
            <option value="category" name="category">Select the new Category</option>
            {
                category === null ? <Spinner /> :
                    category.map((category , index)=>{
                        return(
                            <option key={index+1} value={category.category}>{category.category}</option>
                        )
                    })
            }
        </select> */}
      <select
        onChange={(e) => setData({ ...data, category: e.target.value })}
        value={data.category}
        name="category"
        id="categories_of_people"
        required
      >
        <option defaultValue={"category"} name="category">
          Select the new Category
        </option>
        {category === null ? (
          <option value="">
            <Spinner />
          </option>
        ) : (
          category.map((category, index) => {
            return (
              <option key={index + 1} value={category.category}>
                {category.category}
              </option>
            );
          })
        )}
      </select>
      {errorMessage !== "" && (
        <div className="error">
          <GoIcons.GoAlert color={"red"} />
          <small>{errorMessage}</small>
        </div>
      )}
      <input type="submit" value="Submit" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </form>
  );
}

export default SupportTeam;

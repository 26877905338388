import React, { useContext } from "react";
import { userContext } from "../../App";
import Nav from "../Dashboard/Nav";
import AllTickets from "./AllTickets/AllTickets";
import SupportTicket from "./SupportTickets/SupportTicket";
import "./ticket.css";

function Tickets() {
  const { user } = useContext(userContext);
  return (
    <div className="tickets">
      <div className="tickets-container">
        <h1>All Tickets Page</h1>
        {user.role === "superAdmin" ? <AllTickets /> : <SupportTicket />}
      </div>
      <Nav />
    </div>
  );
}

export default Tickets;

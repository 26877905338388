import React, { useState, useContext } from "react";
import axios from "../../../utils/axios";
import { userContext } from "../../../App";
import { ToastContainer, toast } from "react-toastify";
import * as GoIcons from "react-icons/go";

function DeativateUser({ close }) {
  const { config } = useContext(userContext);
  const [errorMessage, setErrorMessage] = useState("");
  const initialData = {
    deactivateUserEmail: "",
  };
  const [data, setData] = useState(initialData);
  const handleSubmit = async (e) => {
    e.preventDefault();
    axios
      .put(
        "/api/users/user/deactivate",
        data,
        config
      )
      .then(function (response) {
        toast.success("User has been deactivated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setData(initialData);
        setTimeout(() => {
          close();
        }, 5000);
      })
      .catch(function (err) {
        setErrorMessage(err.response.data.message || err.message);
        toast.error("Request failled, Try Again!!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const resetError = () => {
    if (errorMessage) setErrorMessage("");
  };
  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={data.categoryEmail}
        onClick={resetError}
        onChange={(e) =>
          setData({ ...data, deactivateUserEmail: e.target.value })
        }
        placeholder="Enter User's email"
      />
      {errorMessage !== "" && (
        <div className="error">
          <GoIcons.GoAlert color={"red"} />
          <small>{errorMessage}</small>
        </div>
      )}
      <input type="submit" value="Submit" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </form>
  );
}

export default DeativateUser;

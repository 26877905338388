import React, { useState, useContext } from "react";
import axios from "../../../utils/axios"
import { userContext } from "../../../App";
import * as GoIcons from "react-icons/go";
import { ToastContainer, toast } from "react-toastify";

function DeleteCategory({ close, setLoading, category }) {
  const { config } = useContext(userContext);
  const [errorMessage, setErrorMessage] = useState("");
  const initialData = {
    category: category.category,
    categoryEmail: category.categoryEmail,
  };
  const [data, setData] = useState(initialData);
  const handleSubmit = async (e) => {
    e.preventDefault();
    axios
      .delete("/api/category/delete", {
        ...config,
        data: data,
      })
      .then(function (response) {
        toast.success("Category deleted!!!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setData(initialData);
        setTimeout(() => {
          close();
          setLoading(true);
        }, 5000);
      })
      .catch(function (err) {
        setErrorMessage(err.response.data.message || err.message);
        toast.error("Request failled, Try Again!!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={data.category}
        onChange={(e) => setData({ ...data, category: e.target.value })}
        placeholder="Enter the Category"
        readOnly
      />
      <input
        type="text"
        value={data.categoryEmail}
        onChange={(e) => setData({ ...data, categoryEmail: e.target.value })}
        placeholder="Enter the Category Email"
        readOnly
      />
      {errorMessage !== "" && (
        <div className="error">
          <GoIcons.GoAlert color={"red"} />
          <small>{errorMessage}</small>
        </div>
      )}
      <input type="submit" value="Submit" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </form>
  );
}

export default DeleteCategory;

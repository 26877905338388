import React, { useState, useContext } from "react";
import axios from "../../../utils/axios";
import * as FaIcons from "react-icons/fa";
import * as GoIcons from "react-icons/go";
import { userContext } from "../../../App";
import { ToastContainer, toast } from "react-toastify";

function SuperAdmin({ close }) {
  const { config } = useContext(userContext);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const initialData = {
    firstName: "",
    middleName: "",
    lastName: "",
    password: "",
    email: "",
  };
  const [data, setData] = useState(initialData);
  const handleSubmit = async (e) => {
    e.preventDefault();
    axios
      .post("/api/users/super-admin", data, config)
      .then(function (response) {
        toast.success(response.data.remark, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setData(initialData);
        setTimeout(() => {
          close();
        }, 5000);
      })
      .catch(function (err) {
        setErrorMessage(err.response.data.message || err.message);
        toast.error("Request failled, Try Again!!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const resetError = () => {
    if (errorMessage) setErrorMessage("");
  };
  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={data.firstName}
        onClick={resetError}
        onChange={(e) => setData({ ...data, firstName: e.target.value })}
        placeholder="First Name"
      />
      <input
        type="text"
        value={data.middleName}
        onClick={resetError}
        onChange={(e) => setData({ ...data, middleName: e.target.value })}
        placeholder="Middle Name"
      />
      <input
        type="text"
        value={data.lastName}
        onClick={resetError}
        onChange={(e) => setData({ ...data, lastName: e.target.value })}
        placeholder="Last Name"
      />
      <div className="password">
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          onClick={resetError}
          onChange={(e) => setData({ ...data, password: e.target.value })}
        />
        <div
          className="pass-icon"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <FaIcons.FaEyeSlash /> : <FaIcons.FaEye />}
        </div>
      </div>
      <input
        type="email"
        value={data.email}
        onClick={resetError}
        onChange={(e) => setData({ ...data, email: e.target.value })}
        placeholder="Email"
      />
      {errorMessage !== "" && (
        <div className="error">
          <GoIcons.GoAlert color={"red"} />
          <small>{errorMessage}</small>
        </div>
      )}
      <input type="submit" value="Submit" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </form>
  );
}

export default SuperAdmin;

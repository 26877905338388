import React, { useState, useContext } from "react";
import axios from "../../../utils/axios";
import * as GoIcons from "react-icons/go";
import { userContext } from "../../../App";
import { ToastContainer, toast } from "react-toastify";
import "./supportTickets.css";

function ReplyTicket({ close, ticketData, setReplied }) {
  const { config } = useContext(userContext);
  const [errorMessage, setErrorMessage] = useState("");

  console.log(ticketData);
  // const [response, setResponse] = useState("")
  const initialData = {
    complaintId: ticketData._id,
    // complaint: ticketData["complaint"],
    // complaintCategory: ticketData["category"],
    response: "",
  };



  const [data, setData] = useState(initialData);
  console.log(data)
  const handleSubmit = async (e) => {
    e.preventDefault();
    axios
      .post("/api/complaint/response", data, config)
      .then(function (response) {
        toast.success(response.data.remark, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setData(initialData);
        setTimeout(() => {
          close();
          setReplied(true);
        }, 5000);
      })
      .catch(function (err) {
        setErrorMessage(err.response.data.message || err.message);
        toast.error("Request failled, Try Again!!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="input-div">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          className="capitalize"
          value={ticketData.name}
          readOnly
        />
      </div>
      <div className="input-div">
        <label htmlFor="email">Email</label>
        <input type="text" name="email" value={ticketData.email} readOnly />
      </div>
      <div className="input-div">
        <label htmlFor="phoneNumber">PhoneNumber</label>
        <input
          type="text"
          name="phoneNumber"
          value={ticketData.phoneNumber}
          readOnly
        />
      </div>
      <div className="input-div">
        <label htmlFor="classType">Class Type</label>
        <input
          type="text"
          name="classType"
          className="capitalize"
          value={ticketData.classType}
          readOnly
        />
      </div>
      {(ticketData.classType === "student" ||
        ticketData.classType === "staff") && (
        <div className="input-div">
          <label
            htmlFor={
              ticketData.matricId === undefined ? "staffId:" : "matricId"
            }
          >
            {ticketData.matricId === undefined ? "Staff Id:" : "Matric Id"}
          </label>
          <input
            type="text"
            className="upperCase"
            name={ticketData.matricId === undefined ? "staffId:" : "matricId"}
            value={
              ticketData.matricId === undefined
                ? ticketData.staffId
                : ticketData.matricId
            }
            readOnly
          />
        </div>
      )}
      <div className="input-div">
        <label htmlFor="category">Category</label>
        <input
          type="text"
          name="category"
          // className='capitalize'
          value={ticketData.category.category}
          readOnly
        />
      </div>
      <div className="input-div">
        <label htmlFor="categoryEmail">Category Email</label>
        <input
          type="text"
          name="categoryEmail"
          value={ticketData.category.categoryEmail}
          readOnly
        />
      </div>
      <div className="input-div">
        <label htmlFor="complaint">Complaint</label>
        <textarea
          type="text"
          name="complaint"
          value={ticketData.complaint}
          // value={
          //   "Lorem Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, eaque ipsum dolor sit amet consectetur adipisicing elit. Eligendi, eaque"
          // }
          readOnly
        ></textarea>
      </div>

      {errorMessage !== "" && (
        <div className="error">
          <GoIcons.GoAlert color={"red"} />
          <small>{errorMessage}</small>
        </div>
      )}
      <div>
        <label htmlFor="response">Response</label>
      </div>
      <textarea
        type="message"
        name="response"
        onChange={(e) => setData({ ...data, response: e.target.value })}
        value={data.response}
      ></textarea>
      {/* <button className='submit-btn' type="submit">Reply</button> */}
      <input type="submit" value="Reply" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </form>
  );
}

export default ReplyTicket;

import React, { useContext, useEffect, useRef, useState } from "react";
import { userContext } from "../../../App";
import axios from "../../../utils/axios";
import { IoMdArrowDropdown } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiReply } from "react-icons/bi";
import Spinner from "../../Spinner/Spinner";
import "./supportTickets.css";
import ReplyTicket from "./ReplyTicket";

function SupportTicket() {
  const { user, config } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [replied, setReplied] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [ticket, setTicket] = useState("All");
  const [showTicket, setShowTicket] = useState(false);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const tickets = useRef(null);
  const currentTickets = useRef(null);
  // const filteredTickets = useRef(null)
  const num = useRef(null);
  useEffect(() => {
    if (showReplyForm) {
      document.querySelector("body").style.overflow = "hidden";
    }
    return () => {
      document.querySelector("body").style.overflow = "auto";
    };
  }, [showReplyForm]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${
          ticket !== ""
            ? `/api/complaint/fetch?status=${ticket}`
            : "/api/complaint/fetch?recent=true"
        }`,
        config
      )
      .then(function (response) {
        const numArray = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
        tickets.current = response.data.data;
        currentTickets.current = tickets.current.slice(
          tickets.current.length >= 5 ? -5 : -1 * tickets.current.length
        );
        num.current = numArray.slice(
          tickets.current.length >= 10 ? -10 : -1 * tickets.current.length
        );
        setLoading(false);
        setReplied(false);
      })
      .catch(function (err) {
        console.log(err.response.data.message || err.message);
        setLoading(false);
        setReplied(false);
      });
  }, [config, user, ticket, replied]);
  function handle(select) {
    if (select !== ticket) {
      if (select === "all") {
        setTicket("");
      } else {
        setTicket(select);
      }
    }
    setShowTicket(false);
  }
  function show(e) {
    setShowTicket(!showTicket);
  }
  const closeButton = () => {
    setShowReplyForm(false);
  };

  return (
    <div className="support-ticket">
      <div className="buttons">
        <div className="dropdown">
          <div className="dropbtn" onClick={show}>
            <p>{ticket === "" ? "All" : ticket}</p>
            <IoMdArrowDropdown className="down-arrow" />
          </div>
          {showTicket && (
            <div className="dropdown-content">
              <p onClick={() => handle("all")}>all</p>
              <p onClick={() => handle("open")}>Open</p>
              <p onClick={() => handle("closed")}>Closed</p>
            </div>
          )}
        </div>
      </div>
      {showReplyForm && (
        <div className={`reply-form ${showReplyForm && "pop-up"}`}>
          <div className="top">
            <h2>Reply Ticket</h2>
            <AiFillCloseCircle onClick={closeButton} fontSize={30} />
          </div>
          <ReplyTicket
            ticketData={ticketData}
            close={closeButton}
            setLoading={setLoading}
            setReplied={setReplied}
          />
        </div>
      )}
      <div>
        <div
          className={loading ? "desktop-tickets loading" : "desktop-tickets"}
        >
          {currentTickets.current === null || loading ? (
            <Spinner />
          ) : currentTickets.current.length !== 0 ? (
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Class Type</th>
                    {/* <th scope="col">Ticket Category</th> */}
                    <th scope="col">Date of Opening</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {num.current.map((num, index) => {
                    return (
                      <tr key={index + 1}>
                        <td className="table-name">
                          {currentTickets.current[num].name}
                        </td>
                        <td className="table-email">
                          {currentTickets.current[num].email}
                        </td>
                        <td className="class-type">
                          {currentTickets.current[num].classType}
                        </td>
                        <td className="table-date">
                          {currentTickets.current[num].createdAt.substring(
                            0,
                            10
                          )}
                        </td>
                        <td
                          className="table-reply"
                          onClick={() =>
                            setTicketData(currentTickets.current[num])
                          }
                        >
                          <p onClick={() => setShowReplyForm(true)}>reply</p>
                          <BiReply onClick={() => setShowReplyForm(true)} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* <ol>
                                        {
                                        currentTickets.current.map((ticket, index)=>{
                                            return (
                                                <li key={index+1}>
                                                <Link to={`/tickets/${ticket["_id"]}`}>
                                                    <p>{ticket.name}</p>
                                                </Link>
                                                    <small>{ticket.category}</small>
                                                    <p>{ticket.complaint.substring(0, 100)}...</p>
                                                </li>
                                            )
                                        })
                                        }
                                    </ol> */}
            </div>
          ) : (
            <p>
              No recent {(ticket === "open" || ticket === "closed") && ticket}{" "}
              ticket
            </p>
          )}
        </div>
        <p className="mobile">
          You have to login on a desktop to see this page
        </p>
      </div>
    </div>
  );
}

export default SupportTicket;

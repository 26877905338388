import React, { useContext, useRef, useState, useEffect } from "react";
import Nav from "../Dashboard/Nav";
import { userContext } from "../../App";
import axios from "../../utils/axios";
import { IoMdArrowDropdown } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import SuperAdmin from "../../components/User/Form/SuperAdmin";
import SupportTeam from "../../components/User/Form/SupportTeam";
import DeactivateUser from "../../components/User/Form/DeactivateUser";
import UpdateCategory from "../../components/User/Form/UpdateCategory";
import "./user.css";
import "react-toastify/dist/ReactToastify.css";
import ActiveUser from "./ActiveUser";

function User() {
  const { user } = useContext(userContext);
  const [showUserType, setShowUserType] = useState(false);
  const [showAdminForm, setShowAdminForm] = useState(false);
  const [showDeactivateUserForm, setShowDeactivateUserForm] = useState(false);
  const [showUpdateCategoryForm, setShowUpdateCategoryForm] = useState(false);
  const [showSupportForm, setShowSupportForm] = useState(false);
  const category = useRef(null);
  function show(e) {
    setShowUserType(!showUserType);
  }
  const showSuperAdminForm = (e) => {
    setShowUserType(false);
    setShowAdminForm(true);
    setShowSupportForm(false);
    setShowDeactivateUserForm(false);
    setShowUpdateCategoryForm(false);
  };
  const showSupportTeamForm = (e) => {
    setShowUserType(false);
    setShowAdminForm(false);
    setShowSupportForm(true);
    setShowDeactivateUserForm(false);
    setShowUpdateCategoryForm(false);
  };
  const showDeactivateForm = (e) => {
    setShowUserType(false);
    setShowAdminForm(false);
    setShowSupportForm(false);
    setShowDeactivateUserForm(true);
    setShowUpdateCategoryForm(false);
  };
  const showCategoryForm = (e) => {
    setShowUserType(false);
    setShowAdminForm(false);
    setShowSupportForm(false);
    setShowDeactivateUserForm(false);
    setShowUpdateCategoryForm(true);
  };
  const closeButton = () => {
    setShowAdminForm(false);
    setShowSupportForm(false);
    setShowDeactivateUserForm(false);
    setShowUpdateCategoryForm(false);
  };
  useEffect(() => {
    if (
      showAdminForm ||
      showSupportForm ||
      showDeactivateUserForm ||
      showUpdateCategoryForm
    ) {
      document.querySelector("body").style.overflow = "hidden";
    }
    return () => {
      document.querySelector("body").style.overflow = "auto";
    };
  }, [
    showAdminForm,
    showSupportForm,
    showDeactivateUserForm,
    showUpdateCategoryForm,
  ]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get("/api/category");
        console.log(result);
        const jsonResult = await result.data;
        category.current = jsonResult.data;
        sessionStorage.setItem("categories", JSON.stringify(jsonResult.data));
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);

  console.log(category);
  return (
    <>
      {user.role !== "superAdmin" ? (
        <h2>Sorry, you are not authorized to visit the page</h2>
      ) : (
        <div
          className={
            showAdminForm ||
            showSupportForm ||
            showDeactivateUserForm ||
            showUpdateCategoryForm
              ? "user active"
              : "user"
          }
        >
          <div className="user-container">
            <h1>User Page</h1>
            <div>
              <div className="form-buttons">
                <div className="dropdown">
                  <div onClick={show} className="dropbtn">
                    <p>Add User</p>
                    <IoMdArrowDropdown className="down-arrow" />
                  </div>
                  {showUserType && (
                    <div className="dropdown-content">
                      <p onClick={showSuperAdminForm}>Super Admin</p>
                      <p onClick={showSupportTeamForm}>Support Team</p>
                    </div>
                  )}
                </div>
                <button onClick={showDeactivateForm} className="dropbtn">
                  Deactivate User
                </button>
                <button onClick={showCategoryForm} className="dropbtn">
                  Change User's Category
                </button>
              </div>
              {(showAdminForm || showSupportForm) && (
                <div
                  className={`user-form ${
                    (showAdminForm ||
                      showSupportForm ||
                      showDeactivateUserForm ||
                      showUpdateCategoryForm) &&
                    "pop-up"
                  }`}
                >
                  <div className="top">
                    <h2>
                      Add{" "}
                      {showAdminForm ? "Super Admin" : "Support Team member"}
                    </h2>
                    <AiFillCloseCircle onClick={closeButton} fontSize={30} />
                  </div>
                  {showAdminForm && <SuperAdmin close={closeButton} />}
                  {showSupportForm && (
                    <SupportTeam
                      category={category.current}
                      close={closeButton}
                    />
                  )}
                </div>
              )}
              {(showDeactivateUserForm || showUpdateCategoryForm) && (
                <div
                  className={`user-form ${
                    (showDeactivateUserForm || showUpdateCategoryForm) &&
                    "pop-up"
                  }`}
                >
                  <div className="top">
                    <h2>
                      {showDeactivateUserForm
                        ? "Deactivate User"
                        : "Update User Category"}
                    </h2>
                    <AiFillCloseCircle onClick={closeButton} fontSize={30} />
                  </div>
                  {showDeactivateUserForm && (
                    <DeactivateUser close={closeButton} />
                  )}
                  {showUpdateCategoryForm && (
                    <UpdateCategory
                      category={category.current}
                      close={closeButton}
                    />
                  )}
                </div>
              )}
            </div>
            <p className="mobile">
              You have to login on a desktop to see this page
            </p>
            <ActiveUser />
          </div>
          <Nav />
        </div>
      )}
    </>
  );
}

export default User;

import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { HiOutlineTicket } from "react-icons/hi";
import { AiOutlineHome, AiOutlineLogout, AiOutlineClose } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { userContext } from "../../App";
import "./header.css";

function Nav() {
  const { user } = useContext(userContext);
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);
  let localSAuth = JSON.parse(localStorage.getItem("auth"));
  let sessionSAuth = JSON.parse(sessionStorage.getItem("auth"));
  let pathname = window.location.pathname;
  useEffect(() => {
    if (!localSAuth && !sessionSAuth) {
      navigate("/login");
    }
    if (localSAuth || sessionSAuth) {
      if (Date.now() > user.exp * 1000) {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
      }
    }
  }, [navigate, user.exp, localSAuth, sessionSAuth]);
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };
  return (
    <header className={toggleMenu ? "active" : ""}>
      <div className="top">
        <div className="logo">
          <Link to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="hamburger-menu">
          {!toggleMenu && (
            <GiHamburgerMenu
              cursor="pointer"
              fontSize={27}
              className="hamburger"
              onClick={() => setToggleMenu(!toggleMenu)}
            />
          )}
          {toggleMenu && (
            <AiOutlineClose
              cursor="pointer"
              fontSize={27}
              className="close"
              onClick={() => setToggleMenu(false)}
            />
          )}
        </div>
      </div>
      <nav>
        <ul>
          <Link
            className={pathname === "/dashboard" ? "active" : ""}
            to="/dashboard"
          >
            <AiOutlineHome />
            <li>Home</li>
          </Link>
          <Link
            className={pathname === "/tickets" ? "active" : ""}
            to="/tickets"
          >
            {/* <GrTicket color="#fff" /> */}
            <HiOutlineTicket color="#fff" />
            <li>Tickets</li>
          </Link>
          {user && user.role === "superAdmin" && (
            <Link className={pathname === "/users" ? "active" : ""} to="/users">
              <FaUserAlt />
              <li>User</li>
            </Link>
          )}
          {user && user.role === "superAdmin" && (
            <Link
              className={pathname === "/category" ? "active" : ""}
              to="/category"
            >
              <BiCategory />
              <li>Category</li>
            </Link>
          )}
          <Link
            className={pathname === "/profile" ? "active" : ""}
            to="/profile"
          >
            <AiOutlineUser />
            <li>Profile</li>
          </Link>
        </ul>
        <p onClick={logout}>
          <AiOutlineLogout color="#fff" />
          <span>Logout</span>
        </p>
      </nav>
      <p className="brand">Powered by ULININ</p>
    </header>
  );
}

export default React.memo(Nav);

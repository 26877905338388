import React, { useContext, useState, useEffect, useRef } from "react";
import Nav from "../Dashboard/Nav";
import { userContext } from "../../App";
import { AiFillCloseCircle } from "react-icons/ai";
// import {SlOptionsVertical} from 'react-icons/sl'
import AddCategory from "./Form/AddCategory";
import EditCategory from "./Form/EditCategory";
import DeleteCategory from "./Form/DeleteCategory";
import "./category.css";
import axios from "../../utils/axios"
import Spinner from "../Spinner/Spinner";
import TableRow from "./TableRow";

function Category() {
  const { user } = useContext(userContext);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [ind, setInd] = useState(0);
  const [showDeleteCategory, setShowDeleteCategory] = useState(false);
  const [showEditCategory, setShowEditCategory] = useState(false);
  const [loading, setLoading] = useState(true);
  const category = useRef(null);
  const [data, setData] = useState({});

  useEffect(() => {
    if (loading) {
      axios
        .get(`/api/category`)
        .then(function (response) {
          category.current = response.data.data;
          setLoading(false);
        })
        .catch(function (err) {
          console.log(err.response.data.message || err.message);
          setLoading(false);
        });
    }
  });
  const [openState, setOpenState] = useState();
  const hideMenu = () => {
    setOpenState(false);
  };
  const showAddCategoryForm = (e) => {
    setShowAddCategory(true);
    setShowDeleteCategory(false);
    setShowEditCategory(false);
    hideMenu(e);
  };
  const closeButton = () => {
    setShowAddCategory(false);
    setShowDeleteCategory(false);
    setShowEditCategory(false);
  };

  return (
    <>
      {user.role !== "superAdmin" ? (
        <h2>Sorry, you are not authorized to visit the page</h2>
      ) : (
        <div className="category">
          <div className="category-container">
            <h1>Category Page</h1>
            <div className="background-white">
              <div className="category-form-container">
                <div className="form-buttons">
                  <button onClick={showAddCategoryForm} className="dropbtn">
                    Add Category
                  </button>
                </div>
                {(showAddCategory ||
                  showEditCategory ||
                  showDeleteCategory) && (
                  <div className="category-form pop-up">
                    <div className="top">
                      <h2>
                        {showAddCategory
                          ? "Add "
                          : showEditCategory
                          ? "Edit "
                          : "Delete "}
                        Category
                      </h2>
                      <AiFillCloseCircle onClick={closeButton} fontSize={30} />
                    </div>
                    {showAddCategory ? (
                      <AddCategory
                        close={closeButton}
                        setLoading={setLoading}
                      />
                    ) : showEditCategory ? (
                      <EditCategory
                        category={data}
                        close={closeButton}
                        setLoading={setLoading}
                      />
                    ) : (
                      <DeleteCategory
                        category={data}
                        close={closeButton}
                        setLoading={setLoading}
                      />
                    )}
                  </div>
                )}
              </div>
              {category.current === null || loading ? (
                <Spinner />
              ) : (
                <div className="category-table">
                  <h2>Categories</h2>
                  <div className="table-container">
                    <table onClick={hideMenu}>
                      <thead>
                        <tr>
                          <th scope="col">Category</th>
                          <th scope="col">Email</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {category.current.map((category, index) => {
                          return (
                            <TableRow
                              key={index + 1}
                              index={index + 1}
                              setData={setData}
                              ind={ind}
                              setInd={setInd}
                              category={category}
                              setShowAddCategory={setShowAddCategory}
                              setShowEditCategory={setShowEditCategory}
                              setShowDeleteCategory={setShowDeleteCategory}
                              openState={openState}
                              setOpenState={setOpenState}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              <p className="mobile">
                You have to login on a bigger screensize to view, delete or edit
                existing categories
              </p>
            </div>
          </div>
          <Nav />
        </div>
      )}
    </>
  );
}

export default Category;

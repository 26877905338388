import React from "react";
import logo from "../assets/uilogo.png";
import { Box } from "@mui/material";

const Logo = () => {
  return (
    <Box sx={{ width: 100, height: 100 }}>
      <img src={logo} height="100%" width="100%" alt="ui helpdesk" />
    </Box>
  );
};

export default Logo;

import React, { useState, useContext } from "react";
import axios from "../../../utils/axios";
import { userContext } from "../../../App";
import { ToastContainer, toast } from "react-toastify";
import * as GoIcons from "react-icons/go";

function AddCategory({ close, setLoading }) {
  const { config } = useContext(userContext);
  const [errorMessage, setErrorMessage] = useState("");
  const initialData = {
    category: "",
    categoryEmail: "",
    emailPassword: "",
  };
  const [data, setData] = useState(initialData);
  const handleSubmit = async (e) => {
    e.preventDefault();
    axios
      .post("/api/category/add", data, config)
      .then(function (response) {
        toast.success(response.data.remark, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setData(initialData);
        setTimeout(() => {
          close();
          setLoading(true);
        }, 5000);
      })
      .catch(function (err) {
        setErrorMessage(err.response.data.message || err.message);
        toast.error("Request failled, Try Again!!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const resetError = () => {
    if (errorMessage) setErrorMessage("");
  };
  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={data.category}
        onClick={resetError}
        onChange={(e) => setData({ ...data, category: e.target.value })}
        placeholder="Enter the new category"
      />
      <input
        type="text"
        value={data.categoryEmail}
        onClick={resetError}
        onChange={(e) => setData({ ...data, categoryEmail: e.target.value })}
        placeholder="Enter the new category email"
      />
      <input
        type="password"
        value={data.emailPassword}
        onClick={resetError}
        onChange={(e) => setData({ ...data, emailPassword: e.target.value })}
        placeholder="Enter the new category password"
      />
      {errorMessage !== "" && (
        <div className="error">
          <GoIcons.GoAlert color={"red"} />
          <small>{errorMessage}</small>
        </div>
      )}
      <input type="submit" value="Submit" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </form>
  );
}

export default AddCategory;

import React, { useRef, useState, useContext, useEffect } from "react";
import axios from "../../utils/axios";
import { userContext } from "../../App";
import Spinner from "../Spinner/Spinner";

function ActiveUser() {
  const { config } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const users = useRef(null);
  const activeUser = useRef(null);
  const num = useRef(null);
  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/users/fetch-active", config)
      .then(function (response) {
        const numArray = [4, 3, 2, 1, 0];
        users.current = response.data.data;
        console.log(users)
        activeUser.current = users.current.slice(
          users.current.length >= 5 ? -5 : -1 * users.current.length
        );
        num.current = numArray.slice(
          users.current.length >= 5 ? -5 : -1 * users.current.length
        );
        setLoading(false);
      })
      .catch(function (err) {
        console.log(err.response.data.message || err.message);
        setLoading(false);
      });
  }, [config]);
  return (
    <div className="users">
      <h2>Active Users</h2>
      <div className={loading ? "loading" : ""}>
        {activeUser.current === null ? (
          <Spinner />
        ) : activeUser.current.length !== 0 ? (
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col">Category</th>
                </tr>
              </thead>
              <tbody>
                {num.current.map((num, index) => {
                  return (
                    <tr key={index + 1}>
                      <td className="user-name">{`${activeUser.current[num].firstName} ${activeUser.current[num].middleName} ${activeUser.current[num].lastName}`}</td>
                      <td className="user-email">
                        {activeUser.current[num].email}
                      </td>
                      <td className="user-role">
                        {activeUser.current[num].role}
                      </td>
                      <td className="user-category">
                        {activeUser.current[num].role === "superAdmin"
                          ? "admin"
                          : activeUser.current[num].category?.category}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No active user, Kindly add user</p>
        )}
      </div>
    </div>
  );
}

export default ActiveUser;

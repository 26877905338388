import React, { useState, useContext } from "react";
import axios from "../../../utils/axios";
import { userContext } from "../../../App";
import { toast, ToastContainer } from "react-toastify";
import * as GoIcons from "react-icons/go";

function EditCategory({ close, setLoading, category }) {
  const { config } = useContext(userContext);
  const [errorMessage, setErrorMessage] = useState("");
  const initialData = {
    oldCategory: category.category,
    newCategory: "",
    oldCategoryEmail: category.categoryEmail,
    newCategoryEmail: "",
  };
  const [data, setData] = useState(initialData);
  const newCategoryValue = data.newCategory.trim();
  const newCategoryEmailValue = data.newCategoryEmail.trim();
  const checkInputs = () => {
    if (newCategoryValue === "" && newCategoryEmailValue === "") {
      setErrorMessage("new category name or new category email must be filled");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    new Promise(function (resolve, reject) {
      resolve(checkInputs());
    })
      .then(function () {
        if (newCategoryValue === "" && newCategoryEmailValue === "") {
          throw new Error(
            "new category email and new category fields are blank"
          );
        }
      })
      .then(function () {
        axios
          .put("/api/category/edit", data, config)
          .then(function (response) {
            toast.success(response.data.remark, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setData(initialData);
            setTimeout(() => {
              close();
              setLoading(true);
            }, 5000);
          })
          .catch(function (err) {
            setErrorMessage(err.response.data.message || err.message);
            toast.error("Request failled, Try Again!!!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      });
  };
  const resetError = () => {
    if (errorMessage) setErrorMessage("");
  };
  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={data.oldCategory}
        onChange={(e) => setData({ ...data, oldCategory: e.target.value })}
        placeholder="Enter the old category"
        readOnly
      />
      <input
        type="email"
        value={data.oldCategoryEmail}
        onChange={(e) => setData({ ...data, oldCategoryEmail: e.target.value })}
        placeholder="Enter the old category email"
        readOnly
      />
      <input
        type="text"
        value={data.newCategory}
        onClick={resetError}
        onChange={(e) => setData({ ...data, newCategory: e.target.value })}
        placeholder="Enter the new category name"
      />
      <input
        type="email"
        value={data.newCategoryEmail}
        onClick={resetError}
        onChange={(e) => setData({ ...data, newCategoryEmail: e.target.value })}
        placeholder="Enter the new category email"
      />
      {errorMessage !== "" && (
        <div className="error">
          <GoIcons.GoAlert color={"red"} />
          <small>{errorMessage}</small>
        </div>
      )}
      <input type="submit" value="Submit" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </form>
  );
}

export default EditCategory;
